<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-4 g">
      <div class="card">
        <div class="card-header text-center">
          <button
            class="btn btn-relief-primary btn-block btn-lg"
            @click="addNew()"
          >
            <i class="fa fa-plus"></i>
            {{ e("create-new-group") }}
          </button>
        </div>
        <div class="card-body">
          <ul>
            <li v-for="group in groups" :key="group._id">
              <button class="btn btn-link btn-lg" @click="editGroup(group)">
                <u>{{ group.title }}</u>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 g" v-if="v_add">
      <div class="card">
        <div class="card-header text-center">
          <h4>
            <i class="fa fa-plus"></i>
            {{ e("create-new-group") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <h4 for="">{{ e("group-title") }}</h4>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="title"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="col-12 table-responsive">
            <p class="text-muted">{{ e("allowed-days") }}</p>
            <table class="table table-bordered">
              <thead>
                <th>{{ e("day") }}</th>
                <th>{{ e("Saturday") }}</th>
                <th>{{ e("Sunday") }}</th>
                <th>{{ e("Monday") }}</th>
                <th>{{ e("Tuesday") }}</th>
                <th>{{ e("Wednesday") }}</th>
                <th>{{ e("Thursday") }}</th>
                <th>{{ e("Friday") }}</th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ e("select") }}</td>
                  <td v-for="(day, i) in days" :key="i">
                    <b-form-checkbox
                      class="custom-control-primary"
                      name="check-button"
                      v-model="days[i]"
                      value="true"
                      switch
                      inline
                    >
                    </b-form-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div class="row">
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("start_date") }}</h5>
                <input type="time" class="form-control" v-model="start_date" />
              </div>
            </div>
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("late_date") }}</h5>
                <input type="time" class="form-control" v-model="late_date" />
              </div>
            </div>
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("ghiab_date") }}</h5>
                <input type="time" class="form-control" v-model="ghiab_date" />
              </div>
            </div>
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("end_date") }}</h5>
                <input type="time" class="form-control" v-model="end_date" />
              </div>
            </div>
          </div>
          <hr />
          <div class="col-12 g">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="iscontinued"
              value="true"
              switch
              inline
            >
              <h5>
                {{ e("is-continued") }}
              </h5>
            </b-form-checkbox>
          </div>
          <div class="col-12 g">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="istherefreetimes"
              value="true"
              switch
              inline
            >
              <h5>
                {{ e("is-there-freetimes") }}
              </h5>
            </b-form-checkbox>
          </div>
          <div class="col-12 g border" v-if="istherefreetimes">
            <div class="row">
              <div class="col-12">
                <button class="btn btn-link" @click="addFreetime()">
                  <u>
                    <i class="fa fa-plus"></i>
                    {{ e("add-freetime") }}
                  </u>
                </button>
              </div>
              <div v-for="(freetime, i) in freetimes" :key="i" class="col-12 g">
                <div class="row">
                  <div class="col-12 col-lg-5">
                    <div class="form-group">
                      <h5 for="">{{ e("start-time") }}</h5>
                      <input
                        type="time"
                        class="form-control"
                        name=""
                        id=""
                        v-model="freetime.start"
                        aria-describedby="helpId"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-5">
                    <div class="form-group">
                      <h5 for="">{{ e("end-time") }}</h5>
                      <input
                        type="time"
                        class="form-control"
                        name=""
                        id=""
                        v-model="freetime.end"
                        aria-describedby="helpId"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-2">
                    <br />
                    <button
                      class="btn btn-outline-danger"
                      @click="deleteFreetime(i)"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-relief-success btn-lg" @click="createNow()">
              {{ e("create-new-group") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 g" v-if="v_edit">
      <div class="card">
        <div class="card-header text-center">
          <h4>
            <i class="fa fa-edit"></i>
            {{ e("edit-group") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <h4 for="">{{ e("group-title") }}</h4>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="group.title"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="col-12 table-responsive">
            <p class="text-muted">{{ e("allowed-days") }}</p>
            <table class="table table-bordered">
              <thead>
                <th>{{ e("day") }}</th>
                <th>{{ e("Saturday") }}</th>
                <th>{{ e("Sunday") }}</th>
                <th>{{ e("Monday") }}</th>
                <th>{{ e("Tuesday") }}</th>
                <th>{{ e("Wednesday") }}</th>
                <th>{{ e("Thursday") }}</th>
                <th>{{ e("Friday") }}</th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ e("select") }}</td>
                  <td v-for="(day, i) in group.days" :key="day">
                    <b-form-checkbox
                      class="custom-control-primary"
                      name="check-button"
                      v-model="group.days[i]"
                      value="true"
                      switch
                      inline
                    >
                    </b-form-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div class="row">
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("start_date") }}</h5>
                <input
                  type="time"
                  class="form-control"
                  v-model="group.start_date"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("late_date") }}</h5>
                <input
                  type="time"
                  class="form-control"
                  v-model="group.late_date"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("ghiab_date") }}</h5>
                <input
                  type="time"
                  class="form-control"
                  v-model="group.ghiab_date"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 g">
              <div class="form-group">
                <h5 for="">{{ e("end_date") }}</h5>
                <input
                  type="time"
                  class="form-control"
                  v-model="group.end_date"
                />
              </div>
            </div>
          </div>
          <hr />
          <div class="col-12 g">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="group.iscontinued"
              value="true"
              switch
              inline
            >
              <h5>
                {{ e("is-continued") }}
              </h5>
            </b-form-checkbox>
          </div>
          <div class="col-12 g">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="istherefreetimes"
              value="true"
              switch
              inline
            >
              <h5>
                {{ e("is-there-freetimes") }}
              </h5>
            </b-form-checkbox>
          </div>
          <div class="col-12 g border" v-if="istherefreetimes">
            <div class="row">
              <div class="col-12">
                <button class="btn btn-link" @click="addFreetime2()">
                  <u>
                    <i class="fa fa-plus"></i>
                    {{ e("add-freetime") }}
                  </u>
                </button>
              </div>
              <div
                v-for="(freetime, i) in group.freetimes"
                :key="i"
                class="col-12 g"
              >
                <div class="row">
                  <div class="col-12 col-lg-5">
                    <div class="form-group">
                      <h5 for="">{{ e("start-time") }}</h5>
                      <input
                        type="time"
                        class="form-control"
                        name=""
                        id=""
                        v-model="freetime.start"
                        aria-describedby="helpId"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-5">
                    <div class="form-group">
                      <h5 for="">{{ e("end-time") }}</h5>
                      <input
                        type="time"
                        class="form-control"
                        name=""
                        id=""
                        v-model="freetime.end"
                        aria-describedby="helpId"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-2">
                    <br />
                    <button
                      class="btn btn-outline-danger"
                      @click="deleteFreetime2(i)"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-relief-success btn-lg" @click="editNow()">
              {{ e("edit-group") }}
            </button>
            &nbsp;
            <button class="btn btn-outline-danger btn-lg" @click="deleteNow()">
              {{ e("delete-group") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BLink, BOverlay, BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      v_add: false,
      v_edit: false,
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
      iscontinued: false,
      istherefreetimes: false,
      freetimes: [],
      start_date: null,
      late_date: null,
      ghiab_date: null,
      end_date: null,
      title: null,
      group: {},
      days: {
        Saturday: true,
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: false,
      },
    };
  },
  created() {
    this.getGroups();
  },
  methods: {
    getGroups() {
      var g = this;
      $.post(api + "/general/settings-groups/index", {
        jwt: g.user.jwt,
      })
        .then(function (r) {
          g.groups = JSON.parse(r);
          g.loading = false;
        })
        .catch(function (e) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
    addFreetime() {
      this.freetimes.push({
        start: "",
        end: "",
      });
    },
    addFreetime2() {
      this.group.freetimes.push({
        start: "",
        end: "",
      });
    },
    addNew() {
      this.v_add = true;
      this.v_edit = false;
      this.istherefreetimes = false;
    },
    e(d) {
      return e(d);
    },
    deleteFreetime(i) {
      var arr = [];
      this.freetimes.forEach((element, a) => {
        if (i != a) {
          arr.push(element);
        }
      });
      this.freetimes = arr;
    },
    deleteFreetime2(i) {
      var arr = [];
      this.group.freetimes.forEach((element, a) => {
        if (i != a) {
          arr.push(element);
        }
      });
      this.group.freetimes = arr;
    },
    editGroup(group) {
      this.v_add = false;
      this.v_edit = true;
      this.group = group;
      if (group.freetimes.length) {
        this.istherefreetimes = true;
      } else {
        this.istherefreetimes = false;
      }
    },
    createNow() {
      var g = this;
      var obj = {
        jwt: this.user.jwt,
        title: this.title,
        days: this.days,
        start_date: this.start_date,
        late_date: this.late_date,
        ghiab_date: this.ghiab_date,
        end_date: this.end_date,
        iscontinued: this.iscontinued,
        freetimes: this.freetimes,
      };
      if (
        !this.title ||
        !this.start_date ||
        !this.late_date ||
        !this.ghiab_date ||
        !this.end_date
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: e("required-inputs"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        $.post(api + "/general/settings-groups/create", obj)
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("done"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              g.v_add = false;
              g.getGroups();
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e(r.response),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    deleteNow() {
      if (
        confirm(
          e("confirm-delete-group") +
            " '" +
            this.group.title +
            "'?" +
            "\n" +
            e("maybe-affect-reports")
        )
      ) {
        var g = this;
        $.post(api + "/general/settings-groups/delete", {
          jwt: this.user.jwt,
          id: this.group._id,
        })
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("done"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              g.v_edit = false;
              g.getGroups();
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e(r.response),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    editNow() {
      var g = this;
      this.group.jwt = this.user.jwt;
      $.post(api + "/general/settings-groups/edit", this.group)
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.v_add = false;
            g.getGroups();
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>